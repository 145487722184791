import React from "react";
import { ChevronRightIcon, StarIcon } from '@heroicons/react/solid';
import Mobile from "../../assets/images/mobile2.svg";
import Mobile1 from "../../assets/images/mobile2.svg";

export default function WebHero() {
  return (
    <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div className="mt-10 lg:pb-20">
                <div>
                <a href="/tech/" className="inline-flex space-x-4">
                  <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                    What we use
                  </span>
                  <span className="inline-flex items-center text-sm font-medium text-indigo-600 space-x-1">
                    <span>See our tech stack</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                </a>
              </div>
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Mobile Development
                </h1>
                <p className="mt-6 text-xl text-gray-500">
                  Cost-effective, scalable, end-to-end cross-platform app development from initial phase to support and any ongoing app maintenance.
                </p>
              </div>
              <form name="mobile_development" method="POST"
                action="https://formspree.io/f/xbjqdkzr" className="mt-12 sm:max-w-lg sm:w-full sm:flex">
                <div className="min-w-0 flex-1">
                  <label htmlFor="hero_email" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="hero_email"
                    name="email"
                    type="email"
                    className="block w-full border border-gray-300 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-3">
                  <button
                    type="submit"
                    className="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-600 text-base font-medium text-white shadow hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:px-10"
                  >
                    Notify me
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>

        <div className="hidden lg:block">
          <div className="pt-20 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:h-full lg:pl-12">
              <img
                className="w-4/5"
                src={Mobile}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
