/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import Quasar from "../../assets/images/backend/quasar.png";
import Ionic from "../../assets/images/backend/ionic.svg";
import VueLogo from "../../assets/images/tech/vue-js.svg";
import ReactLogo from "../../assets/images/backend/react-native-firebase.svg";
import FlutterLogo from "../../assets/images/backend/flutter.svg";
export default function MobileTech() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">

          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://ionicframework.com/" target="_blank">
              <img className="h-12" src={Ionic} alt="Ionic" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://vue-native.io/" target="_blank">
              <img className="h-12" src={VueLogo} alt="Vue Native" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <a href="https://reactnative.dev/" target="_blank">
              <img className="h-12" src={ReactLogo} alt="React Native" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-3 lg:col-span-1">
            <a href="https://flutter.dev/" target="_blank">
              <img className="h-12" src={FlutterLogo} alt="Flutter" />
            </a>
          </div>
          <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://quasar.dev/" target="_blank">
              <img className="h-12" src={Quasar} alt="Quasar" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
